// eslint-disable-next-line import/prefer-default-export
const environment = {
  product: process.env.REACT_APP_PRODUCT,
  squidex: {
    projectId: process.env.REACT_APP_SQUIDEX_PROJECT_ID,
    editorNetwork: process.env.REACT_APP_STATIONS_EDITOR_NETWORK,
  },
  elastic: {
    host: process.env.REACT_APP_ELASTICSEARCH_HOST,
    authStr: process.env.REACT_APP_ELASTICSEARCH_AUTHSTR,
    indices: {
      omnyClips:
        process.env.REACT_APP_ELASTICSEARCH_CLIPS,
      squidexShows: process.env.REACT_APP_ELASTICSEARCH_SQUIDEXSHOWS,
    },
  },
  firebase: {
    imageTransparencyRemoverUrl: process.env.REACT_APP_FUNCTION_IMAGETRANSPARENCYREMOVER_URL,
  },
};

export default environment;
