import React from 'react';
import PropTypes from 'prop-types';

function Checkbox({ editorId, editorLabel, editorCss, selectedValue, onChange }) {
  editorCss = editorCss ? `form-control checkbox ${editorCss}` : 'form-control checkbox';
  return (
    <div className="input-field">
      <label htmlFor={editorId}>
        {`${editorLabel}: `}
        <input
          type="checkbox"
          id={editorId}
          name={editorId}
          checked={selectedValue || false}
          className={editorCss}
          onChange={onChange}
        />
      </label>
    </div>
  );
}

Checkbox.propTypes = {
  editorId: PropTypes.string.isRequired,
  editorLabel: PropTypes.string.isRequired,
  editorCss: PropTypes.string,
  selectedValue: PropTypes.bool,
  onChange: PropTypes.func,
};

Checkbox.defaultProps = {
  editorCss: '',
  selectedValue: false,
  onChange: () => {},
};

export default Checkbox;
