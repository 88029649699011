import React from 'react';
import PropTypes from 'prop-types';

export default function Select(props) {
  const { onChange, name, id, className, value, values } = props;
  return (
    <select name={name} id={id || name} className={className} value={value || ''} onChange={onChange}>
      <option disabled value="" />
      {values.map(item => <option key={`${item}`} value={item}>{item}</option>)}
    </select>
  );
}

Select.defaultProps = {
  id: null,
  className: null,
  value: null,
};

Select.propTypes = {
  values: PropTypes.arrayOf(PropTypes.string).isRequired,
  onChange: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  id: PropTypes.string,
  className: PropTypes.string,
  value: PropTypes.string,
};
