import React from 'react';

import CuratedStationsEditor from '../CuratedStationsEditor';
import { getSquidexStations } from '../../utilities/api/pconeXLApi';
import environment from '../../environment';
import { product } from '../../constant';

function CuratedStationsByNetworkEditor() {
  const squidexStations = environment.product === product.podcastone ? [] : getSquidexStations;
  return (
    <CuratedStationsEditor
      squidexStations={squidexStations}
    />
  );
}

export default CuratedStationsByNetworkEditor;
