/* eslint-disable no-console */
import React, { useState, useEffect, useCallback } from 'react';
import { get } from 'lodash';
import HTML5Backend from 'react-dnd-html5-backend';
import { DndProvider } from 'react-dnd';
import SortableCard from '../SortableCard';
import { getSquidexShowsByCategorySlug } from '../../utilities/api/pconeXLApi';

const sortByKey = (array, order, key) => {
  array.sort((a, b) => {
    let indexA = order.indexOf(a[key]);
    let indexB = order.indexOf(b[key]);
    indexA = indexA < 0 ? order.length : indexA;
    indexB = indexB < 0 ? order.length : indexB;

    if (indexA < indexB) {
      return -1;
    }
    if (indexA > indexB) {
      return 1;
    }
    return a.slug.localeCompare(b.slug);
  });
  return array;
};

function CuratedShowsByCategoryEditor() {
  const [customField, setCustomField] = useState({});
  const [shows, setShows] = useState([]);
  const [curatedShows, setCuratedShows] = useState([]);

  useEffect(() => {
    // Initializes a custom squidex field and fetches the shows
    const field = new window.SquidexFormField();
    const initShows = [...shows];
    let combinedSavedWithNewShows = [];

    field.onInit(async (context) => {
      const categorySlug = get(context, 'initialContent.data.slug.iv', null);
      if (context) {
        const showsByCategory = await getSquidexShowsByCategorySlug(categorySlug);
        showsByCategory.forEach((item) => {
          initShows.push(item);
        });
        setShows(initShows);

        // console.log('onInit > initShows', initShows, 'savedShows', combinedSavedWithNewShows);
        const savedPlaylistIds = combinedSavedWithNewShows.map(x => x.playlistId);
        // sort initShows (should include old saved + any new additions) by squidex saved show order
        combinedSavedWithNewShows = sortByKey(initShows, savedPlaylistIds, 'playlistId');
        const newCuratedShows = [...combinedSavedWithNewShows];
        setCuratedShows(newCuratedShows);
        field.valueChanged(JSON.stringify(newCuratedShows));
      }
    });

    // Loads saved data
    field.onValueChanged((value) => {
      // console.log('onValueChanged > initShows', initShows.length, 'value', value, 'curatedShows', curatedShows);
      if (!value) {
        setCuratedShows(initShows);
        return;
      }
      const savedShows = JSON.parse(value);
      const savedShowIds = savedShows.map(x => x.playlistId);
      combinedSavedWithNewShows = [...savedShows];
      // sort initShows (should include old saved + any new additions) by newly re-ordered/saved show order
      const newCuratedShows = [...sortByKey(initShows, savedShowIds, 'playlistId')];
      setCuratedShows(newCuratedShows);
    });

    setCustomField(field);
  }, []);

  // React DnD function that sorts the playlist
  const moveCard = useCallback(
    (startIndex, endIndex) => {
      const newCuratedShow = [...curatedShows];

      const dragCard = newCuratedShow[startIndex];
      newCuratedShow[startIndex] = newCuratedShow[endIndex];
      newCuratedShow[endIndex] = dragCard;

      setCuratedShows(newCuratedShow);
      customField.valueChanged(JSON.stringify(newCuratedShow));
      console.log('moveCard > newCuratedShow', newCuratedShow);
    },
    [curatedShows],
  );

  return (
    <div className="container-fluid show-wrapper">
      <DndProvider backend={HTML5Backend}>
        {
          curatedShows.map((item, i) => (
            <SortableCard
              key={item.playlistId || 0}
              index={i}
              id={item.playlistId || '0'}
              moveCard={moveCard}
            >
              <div className="shoe-item">
                <div className="row">
                  <div className="col">
                    <label htmlFor={item.name}>{item.name}</label>
                  </div>
                  <div className="col">
                    <label htmlFor={item.slug}>{item.slug}</label>
                  </div>
                  <div className="col">
                    <label htmlFor={item.playlistId}>{item.playlistId}</label>
                  </div>
                </div>
              </div>
            </SortableCard>
          ))
        }
      </DndProvider>
    </div>
  );
}

export default CuratedShowsByCategoryEditor;
