import React from 'react';
import { ACTION_TYPES } from './hooks/schedule/useSchedule';
import Checkbox from './components/Checkbox';

const stateAndTimezoneFields = [
  { name: 'NSW', value: { state: 'NSW', timezone: 'UTC+10' } },
  { name: 'QLD', value: { state: 'OLD', timezone: 'UTC+10' } },
  { name: 'VIC', value: { state: 'VIC', timezone: 'UTC+10' } },
  { name: 'ACT', value: { state: 'ACT', timezone: 'UTC+10' } },
  { name: 'TAS', value: { state: 'TAS', timezone: 'UTC+10' } },
  { name: 'NT', value: { state: 'NT', timezone: 'UTC+9:30' } },
  { name: 'SA', value: { state: 'SA', timezone: 'GMT+9:30' } },
  { name: 'WA', value: { state: 'WA', timezone: 'GMT+8' } },
];

export default function Timezones({ dispatch, timezones, sectionIndex }) {
  function timezoneCheckBoxValue(item) {
    const values = timezones || [];
    return !!values.find(timezone => timezone.state === item.value.state);
  }

  function handleOnChange(event, i) {
    const { value, checked } = event.target;
    dispatch({ type: ACTION_TYPES.UPDATE_TIMEZONE, payload: { index: i, sectionIndex, value: JSON.parse(value), checked } });
  }

  return (
    stateAndTimezoneFields.map((item, index) => {
      const id = `${item.name}-${index}`;
      return (
        <Checkbox
          data-testid={id}
          checked={timezoneCheckBoxValue(item)}
          id={id}
          key={id}
          label={item.name}
          {...item}
          value={JSON.stringify(item.value)}
          onChange={event => handleOnChange(event, index)}
        />
      );
    })
  );
}
