import React from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
} from 'react-router-dom';
import './App.css';
import ImageTransparencyRemover from './components/ImageTransparencyRemover';
import CuratedEpisodesEditor from './components/CuratedEpisodesEditor';
import CuratedTrailersEditor from './components/CuratedTrailersEditor';
import CuratedShowsByCategoryEditor from './components/CuratedShowsByCategoryEditor';
import CuratedStationsByNetworkEditor from './components/CuratedStationsByNetworkEditor';
import Scheduling from './components/SchedulingEditor';

const prouctName = process.env.REACT_APP_PRODUCT;

function App() {
  return (
    <div className="App">
      <Router>
        <Switch>
          <Route path="/playlist_creator">
            <CuratedEpisodesEditor />
          </Route>
          <Route path="/curated_trailer_editor">
            <CuratedTrailersEditor />
          </Route>
          <Route path="/curated-episode-editor">
            <CuratedEpisodesEditor />
          </Route>
          <Route path="/curated-showsbycategory-editor">
            <CuratedShowsByCategoryEditor />
          </Route>
          <Route path="/curated-stationsbynetwork-editor">
            <CuratedStationsByNetworkEditor />
          </Route>
          <Route path="/image-transparency-remover">
            <ImageTransparencyRemover />
          </Route>
          <Route path="/scheduling">
            <Scheduling />
          </Route>
          <Route path="/">
            <h1>
              {`${prouctName.toUpperCase()} Squidex Custom Editors Home Page`}
            </h1>
          </Route>
        </Switch>
      </Router>
    </div>
  );
}

export default App;
