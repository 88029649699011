/* eslint-disable no-console */

import React from 'react';
import PropTypes from 'prop-types';

export default function TextInput(props) {
  const { isRequired, ...restProps } = props;
  return (
    <div className={`input-field ${restProps.className}`}>
      <label htmlFor={restProps.name} className="form-label visually-hidden">{props.label}{isRequired ? ' *' : ''}</label>
      <input type="text" {...restProps} className={`form-control ${props.className}`} />
    </div>
  );
}

TextInput.defaultProps = {
  value: '',
  isRequired: false,
  className: '',
};

TextInput.propTypes = {
  name: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string,
  isRequired: PropTypes.bool,
  className: PropTypes.string,
};
