import React from 'react';
import SearchShows from '../SearchShows';
import {
  fetchPodcastOneEpisodes,
  fetchPodcastOneShows,
} from '../../utilities/api/pconeApi';

import { getFilteredShows, getClipsByPlaylistId, getFilteredShowsPlaylistId } from '../../utilities/api/pconeXLApi';
import environment from '../../environment';
import { product } from '../../constant';

function CuratedEpisodesEditor() {
  const squidexShows = environment.product === product.podcastone ? fetchPodcastOneShows : getFilteredShows;
  const clipsApi = environment.product === product.podcastone ? fetchPodcastOneEpisodes : getClipsByPlaylistId;
  const filteredShowsInitial = environment.product === product.podcastone ? fetchPodcastOneShows : getFilteredShowsPlaylistId;
  return (
    <SearchShows
      squidexShows={squidexShows}
      filteredShowsInitial={filteredShowsInitial}
      clipsApi={clipsApi}
      showIsLatestField
      showCommentFields
    />
  );
}

export default CuratedEpisodesEditor;

