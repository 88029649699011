import PropTypes from 'prop-types';
import React, { useState, useEffect, useCallback } from 'react';
import HTML5Backend from 'react-dnd-html5-backend';
import { DndProvider } from 'react-dnd';
import StationSelector from './StationSelector';
import SortableCard from '../SortableCard';

function CuratedStationsEditor({ squidexStations }) {
  const [customField, setCustomField] = useState({});
  const [station, setStation] = useState([]);
  const [stationsDropdown, setStationDropdown] = useState([{ text: '- Select -', value: '' }]);

  useEffect(() => {
    // Initializes a custom squidex field and fetches the shows
    const field = new window.SquidexFormField();

    field.onInit(async (context) => {
      if (context) {
        const squidexStationsData = await squidexStations(context);
        const stationsDropdownData = [...stationsDropdown];
        squidexStationsData.forEach((squidexStation) => {
          stationsDropdownData.push({ text: squidexStation.name, value: squidexStation.slug });
        });

        setStationDropdown(stationsDropdownData);
      }
    });

    // Loads saved data
    field.onValueChanged((value) => {
      if (!value) {
        return;
      }
      setStation(JSON.parse(value));
    });

    setCustomField(field);
  }, []);

  const handleOnSelectStation = ({
    index,
    name,
    slug,
  }) => {
    const newStation = [...station];
    newStation[index] = {
      name,
      slug,
    };

    setStation(newStation);
    // Only keep if slug and episodeId are selected
    const validList = newStation.filter(x => x.slug);
    customField.valueChanged(JSON.stringify(validList));
  };

  const handleOnDeleteItem = (index) => {
    const newStatoin = [...station];
    newStatoin.splice(index, 1);

    setStation(newStatoin);
    customField.valueChanged(JSON.stringify(newStatoin));
  };

  const handleNewItemClick = () => {
    const newStation = [...station];
    // Don't allow to create empty entries in the station
    if (newStation.length === 0 || newStation[newStation.length - 1].slug) {
      newStation.push({});
      setStation(newStation);
    }
  };

  // React DnD function that sorts the station
  const moveCard = useCallback(
    (startIndex, endIndex) => {
      const newStation = [...station];
      const dragCard = newStation[startIndex];
      newStation[startIndex] = newStation[endIndex];
      newStation[endIndex] = dragCard;
      setStation(newStation);
      customField.valueChanged(JSON.stringify(newStation));
    },
    [station],
  );

  return (
    <div className="container-fluid station-wrapper">
      <DndProvider backend={HTML5Backend}>
        {
            station.map((item, i) => (
              <SortableCard
                key={item.slug || 0}
                index={i}
                id={item.slug || '0'}
                moveCard={moveCard}
              >
                <StationSelector
                  key={item.slug}
                  index={i}
                  stations={stationsDropdown}
                  onSelectStation={handleOnSelectStation}
                  onDeleteItem={handleOnDeleteItem}
                  selectedItem={item}
                />
              </SortableCard>
            ))
          }
      </DndProvider>
      <div className="new-item">
        <button type="button" onClick={handleNewItemClick} className="btn btn-success">
            New item
        </button>
      </div>
    </div>
  );
}

CuratedStationsEditor.propTypes = {
  squidexStations: PropTypes.func.isRequired,
};

CuratedStationsEditor.defaultProps = {
};

export default CuratedStationsEditor;
