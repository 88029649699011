import React from 'react';
import PropTypes from 'prop-types';

export default function Checkbox(props) {
  const { isSwitch, ...restProps } = props;

  return (
    <div key={props.name} className={`text-start form-check ${isSwitch ? 'form-switch' : 'form-check-inline'}`}>
      <label htmlFor={props.id || props.name} className="form-check-label">{props.label}</label>
      <input {...restProps} type="checkbox" className="form-check-input me-1" />
    </div>
  );
}

Checkbox.defaultProps = {
  checked: false,
  isSwitch: false,
  label: '',
};

Checkbox.propTypes = {
  name: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  label: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  checked: PropTypes.bool,
  isSwitch: PropTypes.bool,
};
