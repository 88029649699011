/* eslint-disable no-console */
import React from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';

import TextInput from './components/TexInput';
import { ACTION_TYPES } from './hooks/schedule/useSchedule';
import Checkbox from './components/Checkbox';
import Select from '../Select';
// import generateKey from './utils/generateKey';

const Elements = {
  Checkbox,
  Select,
  TextInput,
};

const inputElements = [
  {
    type: 'checkbox',
    name: 'isScheduled',
    element: 'Checkbox',
    isSwitch: true,
  },
  {
    type: 'select',
    values: ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'],
    name: 'day',
    className: 'me-3 p-2',
    element: 'Select',
  },
  {
    type: 'text',
    name: 'start',
    label: 'start',
    isRequired: true,
    placeholder: '13:00',
    value: 'start',
    className: 'col-1 me-3',
    element: 'TextInput',
  },
  {
    type: 'text',
    name: 'end',
    label: 'end',
    isRequired: true,
    placeholder: '14:00',
    className: 'col-1 me-2',
    element: 'TextInput',
  },
];

export default function ScheduleItem({ dispatch, sectionIndex, scheduleIndex, scheduleData }) {
  function handleOnChange(event) {
    const { name, type } = event.target;
    const value = type === 'checkbox' ? event.target.checked : event.target.value;

    dispatch({
      type: ACTION_TYPES.HANDLE_SCHEDULE_ITEM_CHANGE,
      payload: {
        sectionIndex,
        scheduleIndex,
        value,
        name,
      },
    });
  }

  function handleRemoveScheduleItem() {
    dispatch({
      type: ACTION_TYPES.REMOVE_SCHEDULE_ITEM,
      payload: {
        sectionIndex,
        scheduleIndex,
      },
    });
  }

  return (
    <>
      {inputElements.map((item) => {
        const { element, ...props } = item;

        const FormElement = Elements[element];

        // eslint-disable-next-line
        const id = `${props.name}-${sectionIndex}-${scheduleIndex}`;
        const value = get(scheduleData, props.name, null);
        return (
          <FormElement
            {...props}
            key={`scheduleItem-${scheduleData.id}-${props.name}`}
            id={id}
            value={value || ''}
            checked={value || false}
            onChange={handleOnChange}
          />
        );
      })}

      <i data-testid="remove-schedule-item" role="button" tabIndex={0} aria-hidden="true" className="bi-trash text-danger p-2" onClick={() => handleRemoveScheduleItem(sectionIndex, scheduleIndex)} />
    </>
  );
}

ScheduleItem.defaultProps = {
  scheduleData: {},
};

ScheduleItem.propTypes = {
  scheduleIndex: PropTypes.number.isRequired,
  sectionIndex: PropTypes.number.isRequired,
  dispatch: PropTypes.func.isRequired,
  scheduleData: PropTypes.shape({
    id: PropTypes.string,
    isScheduled: PropTypes.bool,
    day: PropTypes.string,
    start: PropTypes.string,
    end: PropTypes.string,
  }),
};
