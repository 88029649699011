/* eslint-disable no-console */
const handleScheduleItemInput = (scheduleIndex, sectionIndex, scheduleData, value, name) => scheduleData.map((item, itemIndex) => {
  if (itemIndex === sectionIndex) {
    const { timezones = [], schedule = [] } = item;

    if (schedule.length < scheduleIndex + 1) {
      return { timezones, schedule: [...schedule, { [name]: value }] };
    }

    const updatedSchedule = schedule.map((scheduleItem, i) => {
      if (i === scheduleIndex) {
        return { ...scheduleItem, [name]: value };
      }
      return scheduleItem;
    });

    return { timezones, schedule: updatedSchedule };
  }
  return item;
});

export default handleScheduleItemInput;
