import React from 'react';
import PropTypes from 'prop-types';

function Textarea({ editorId, editorLabel, editorCss, selectedValue, onChange }) {
  editorCss = editorCss ? `form-control textarea ${editorCss}` : 'form-control textarea';
  return (
    <div className="input-field">
      <label htmlFor={editorId}>
        {`${editorLabel}: `}
        <textarea
          type="text"
          row="3"
          id={editorId}
          name={editorId}
          value={selectedValue || ''}
          className={editorCss}
          onChange={onChange}
        />
      </label>
    </div>
  );
}

Textarea.propTypes = {
  editorId: PropTypes.string.isRequired,
  editorLabel: PropTypes.string.isRequired,
  editorCss: PropTypes.string,
  selectedValue: PropTypes.string,
  onChange: PropTypes.func,
};

Textarea.defaultProps = {
  editorCss: '',
  selectedValue: '',
  onChange: () => {},
};

export default Textarea;
