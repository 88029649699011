/* eslint-disable no-console */
import { useEffect, useReducer } from 'react';
import { arrayMoveImmutable } from 'array-move';

import queryLiveSchedulingData from '../../api/queryLiveSchedulingData';
import { addScheduleItems, removeScheduleItems } from './scheduleItems';
import { addScheduleSection, removeScheduleSection } from './scheduleSections';
import { scheduleItemData, scheduleInitialState } from './data';
import handleStateSelection from './handleStateSelection';
import handleScheduleItemInput from './handleScheduleItemInput';

export const ACTION_TYPES = {
  SET_INITIAL_STATE: 'SET_INITIAL_STATE',
  ADD_SCHEDULE_SECTION: 'ADD_SCHEDULE_SECTION',
  REMOVE_SCHEDULE_SECTION: 'REMOVE_SCHEDULE_SECTION',
  ADD_SCHEDULE_ITEM: 'ADD_SCHEDULE_ITEM',
  REMOVE_SCHEDULE_ITEM: 'REMOVE_SCHEDULE_ITEM',
  UPDATE_TIMEZONE: 'UPDATE_TIMEZONE',
  HANDLE_SCHEDULE_ITEM_CHANGE: 'HANDLE_SCHEDULE_ITEM_CHANGE',
  SET_NATIONWIDE: 'SET_NATIONWIDE',
  SET_LIVE: 'SET_LIVE',
  SORT_SCHEDULES: 'SORT_SCHEDULES',
};

function reducer(state, action) {
  switch (action.type) {
    case ACTION_TYPES.SET_NATIONWIDE: {
      return { ...state, national: !state.national };
    }

    case ACTION_TYPES.SET_LIVE: {
      return { ...state, live: !state.live };
    }

    case ACTION_TYPES.SET_INITIAL_STATE: {
      const { schedules = [], live = false, national = false } = action.payload;

      return {
        ...state,
        national,
        live,
        scheduleData: schedules,
      };
    }

    case ACTION_TYPES.HANDLE_SCHEDULE_ITEM_CHANGE: {
      const { scheduleIndex, sectionIndex, value, name } = action.payload;

      const updatedState = handleScheduleItemInput(scheduleIndex, sectionIndex, state.scheduleData, value, name);
      return { ...state, scheduleData: updatedState };
    }

    case ACTION_TYPES.UPDATE_TIMEZONE: {
      const { sectionIndex, value, checked } = action.payload;
      const { scheduleData = [] } = state;
      const updatedState = handleStateSelection(sectionIndex, scheduleData, value, checked);

      return { ...state, scheduleData: updatedState };
    }

    case ACTION_TYPES.ADD_SCHEDULE_SECTION: {
      return {
        ...state,
        scheduleData: addScheduleSection(state.scheduleData, scheduleItemData()),
      };
    }

    case ACTION_TYPES.REMOVE_SCHEDULE_SECTION: {
      return {
        ...state,
        scheduleData: removeScheduleSection(state.scheduleData, action.sectionIndex),
      };
    }

    case ACTION_TYPES.ADD_SCHEDULE_ITEM: {
      return {
        ...state,
        scheduleData: addScheduleItems(state.scheduleData, action?.payload?.sectionIndex, scheduleItemData()),
      };
    }

    case ACTION_TYPES.REMOVE_SCHEDULE_ITEM: {
      const { sectionIndex, scheduleIndex } = action?.payload;
      return {
        ...state,
        scheduleData: removeScheduleItems(state.scheduleData, sectionIndex, scheduleIndex),
      };
    }

    case ACTION_TYPES.SORT_SCHEDULES: {
      const { sectionIndex, oldIndex, newIndex } = action.payload;

      const updateScheduleData = state.scheduleData.map((items, index) => {
        if (index === sectionIndex) return { ...items, schedule: [...arrayMoveImmutable(items.schedule, oldIndex, newIndex)] };
        return items;
      });

      return {
        ...state,
        scheduleData: [...updateScheduleData],
      };
    }

    default:
      return state;
  }
}

export default function useSchedule(customField) {
  const [state, dispatch] = useReducer(reducer, scheduleInitialState);

  useEffect(() => {
    customField.onInit(async (editor) => {
      try {
        const result = await queryLiveSchedulingData(editor);
        dispatch({ type: ACTION_TYPES.SET_INITIAL_STATE, payload: result });
      } catch (error) {
        console.log(error, 'Error setting initial state useSchedule');
      }
    });
  }, []);

  customField.valueChanged({
    schedules: state.scheduleData,
    live: state.live,
    national: state.national,
  });

  return [state, dispatch];
}
