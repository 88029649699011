/* eslint-disable no-console */
import React from 'react';
import { PropTypes } from 'prop-types';
import { ACTION_TYPES } from './hooks/schedule/useSchedule';
import Checkbox from './components/Checkbox';

function NationalAndLiveSchedule({ dispatch, live }) {
  function handleAddLive() {
    dispatch({ type: ACTION_TYPES.SET_LIVE });
  }

  return (
    <>
      <Checkbox
        id="live"
        isSwitch
        onChange={handleAddLive}
        label="Live show"
        checked={live}
        name="Live"
      />
      <p className="text-muted"><small>When selecting &quot;Live&quot; all start and end times are assumed Sydney UTC, otherwise times are localised to the location</small></p>
    </>
  );
}

NationalAndLiveSchedule.propTypes = {
  dispatch: PropTypes.func.isRequired,
  live: PropTypes.bool.isRequired,
};

export default NationalAndLiveSchedule;
