import React from 'react';
import * as ReactSort from 'react-sortable-hoc';
import ScheduleItem from './ScheduleItem';

export const SortableContainer = ReactSort.SortableContainer(({ children }) => children);
const DragHandle = ReactSort.SortableHandle(() => <div className="sortingHandle" />);

export const SortableScheduleItem = ReactSort.SortableElement(({ dispatch, sectionIndex, scheduleIndex, scheduleData }) => (
  <li className="d-flex align-items-center mb-3" key={`sortableScheduleItem-${scheduleData.id}`}>
    <DragHandle />
    <ScheduleItem
      dispatch={dispatch}
      sectionIndex={sectionIndex}
      scheduleIndex={scheduleIndex}
      scheduleData={scheduleData}
    />
  </li>
));
