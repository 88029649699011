import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import axios from 'axios';
import { ChromePicker } from 'react-color';
import environment from '../../environment';

function ImageTransparencyRemover() {
  const [noImageSourceFound, setNoImageSourceFound] = useState(false);
  const [sourceImage, setSourceImage] = useState(null);
  const [colour, setColour] = useState('#FFFFFF');
  const [assetSourceSchemaFieldName, setAssetSourceSchemaFieldName] = useState(null);
  const [flattenedImageSource, setFlattenedImageSource] = useState();
  const [customField] = useState(new window.SquidexFormField());
  const location = useLocation();
  const assetSourceField = new URLSearchParams(location.search).get('assetSourceField');

  const flattenImage = async () => {
    const context = customField.getContext();
    // get asset from squidex
    const downloadedImage = await axios.get(sourceImage, { responseType: 'arraybuffer' });
    // flatten image by posting to firebase image flattening function and awaiting result
    const config = {
      method: 'post',
      url: environment.firebase.imageTransparencyRemoverUrl,
      headers: {
        backgroundColor: colour,
        'Content-Type': 'image/png',
      },
      data: Buffer.from(downloadedImage.data),
      responseType: 'arraybuffer',
    };

    const opaqueFilename = `${customField.getFormValue().slug.iv}_opaque.png`;
    let opaqueImage = null;
    await axios(config)
      .then((response) => {
        opaqueImage = response.data;
      });

    // upload the image as an asset to squidex using axios.... can't get this to work so using fetch api instead
    const token = context.user.user.access_token;
    const bearerHeader = new Headers();
    bearerHeader.append('Authorization', `Bearer ${token}`);

    const formData = new FormData();
    formData.append('file', new Blob([opaqueImage], { type: 'image/png' }), opaqueFilename);

    // show preview of image
    const reader = new FileReader();
    reader.onload = (e) => {
      setFlattenedImageSource(e.target.result);
    };
    reader.readAsDataURL(new Blob([opaqueImage]));

    const requestOptions = {
      method: 'POST',
      headers: bearerHeader,
      body: formData,
      redirect: 'follow',
    };

    let assetResult;
    await fetch(`${context.apiUrl}/apps/${context.appName}/assets`, requestOptions)
      .then(async (result) => {
        assetResult = await result.json();
        const jsonObject = {};

        // eslint-disable-next-line no-underscore-dangle
        jsonObject[`${assetSourceSchemaFieldName}_flattenedImage`] = `${context.apiUrl.replace('/api', '')}${assetResult._links.content.href}`;
        jsonObject.selectedColour = colour;
        customField.valueChanged(jsonObject);
      });
  };

  useEffect(() => {
    customField.onInit(async (context) => {
      if (context) {
        setAssetSourceSchemaFieldName(assetSourceField);
        if (context.initialContent.data?.imageFlattener?.iv?.selectedColour) {
          // eslint-disable-next-line camelcase
          setColour(context.initialContent.data.imageFlattener.iv.selectedColour);
        }
        if (context.initialContent.data[assetSourceField]?.iv) {
          setSourceImage(`${context.apiUrl}/assets/${context.appName}/${context.initialContent.data[assetSourceField]?.iv}`);
          setNoImageSourceFound(false);
        } else {
          setNoImageSourceFound(true);
        }
      }
    });

    return function cleanUp() {
      customField.onFormValueChanged = null;
      customField.onValueChanged = null;
      customField.onInit = null;
    };
  }, []);

  const handleGenerateImageClick = () => {
    flattenImage();
  };

  const handleChangeComplete = (color) => {
    setColour(color.hex);
  };

  // eslint-disable jsx-one-expression-per-line
  return (
    <div className="container-fluid">
      <div className="row padding">
        <ChromePicker
          color={colour}
          onChangeComplete={handleChangeComplete}
        />
      </div>
      <div className="row padding">
        { noImageSourceFound && <span>Please save the image in field &apos;{ assetSourceField }&apos; before clicking the Generate Flattened Image button and reload the page</span> }
        <button id="btnGenerateFlattenedImage" onClick={handleGenerateImageClick} type="button" disabled={noImageSourceFound}>Generate Flattened Image</button>
      </div>
      <div className="row padding">
        {flattenedImageSource != null ? <img src={flattenedImageSource} alt="flattened preview" className="squareImage" /> : null }
      </div>
    </div>
  );
}

export default ImageTransparencyRemover;
