/* eslint-disable no-console */
import axios from 'axios';
import { get, isEmpty } from 'lodash';
import environment from '../../../environment';

export default async function queryLiveSchedulingData(context) {
  try {
    const baseUrl = `${context.apiUrl}/content/${environment.squidex.projectId}/graphql`;

    const queryData = JSON.stringify({
      query: `query {
        findShowContent(id: "${context?.initialContent?.id}") {
          id
          data {
            scheduling {
              iv
            }
          }
        }
      }`,
    });

    const squidexShowData = await axios.post(baseUrl, queryData, {
      responseType: 'json',
      headers: {
        Authorization: `Bearer ${context.user.user.access_token}`,
        'Content-Type': 'application/json',
      },
    });

    const squidexShowScheduling = get(squidexShowData, 'data.data.findShowContent.data.scheduling.iv', []);

    if (isEmpty(squidexShowScheduling)) {
      return [];
    }

    return squidexShowScheduling;
  } catch (error) {
    console.log(error, 'ERROR in queryLiveSchedulingData');
    return [];
  }
}
