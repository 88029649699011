/* eslint-disable no-console */
import React from 'react';

import { PropTypes } from 'prop-types';
import ScheduleItems from './ScheduleItems';
import ScheduleSection from './ScheduleSection';
import NationalAndLiveSchedule from './NationalAndLiveSchedule';
import Timezones from './TImezones';

import useSchedule, { ACTION_TYPES } from './hooks/schedule/useSchedule';

export default function Scheduling({ customField }) {
  const [scheduling, dispatch] = useSchedule(customField);

  function handleAddSection() {
    dispatch({ type: ACTION_TYPES.ADD_SCHEDULE_SECTION });
  }

  const { live, national, scheduleData = [] } = scheduling;

  return (
    <section className="text-start mb-3">
      <h2>Scheduling</h2>
      <p className="text-muted mb-3">Configure scheduling for each steam location/time.</p>

      <NationalAndLiveSchedule
        dispatch={dispatch}
        national={national}
        live={live}
      />

      {scheduleData.map((data, index) => (
        <ScheduleSection
          key={data.id}
          dispatch={dispatch}
          sectionIndex={index}
          scheduleData={data}
        >
          <Timezones
            dispatch={dispatch}
            sectionIndex={index}
            timezones={scheduleData[index].timezones}
          />
          <ScheduleItems
            scheduleData={data}
            sectionIndex={index}
            dispatch={dispatch}
          />
        </ScheduleSection>
      )) || null}

      <div className="text-start">
        <button type="button" className="btn btn-outline-success mt-3" onClick={handleAddSection}>Add Schedule</button>
      </div>
    </section>
  );
}

Scheduling.propTypes = {
  customField: PropTypes.shape({}).isRequired,
};
