/* eslint-disable no-console */

export const addScheduleItems = (scheduleData, sectionIndex, scheduleItemData) => scheduleData.map((item, i) => (
  i === sectionIndex
    ? { ...item, schedule: [...item.schedule, ...scheduleItemData] }
    : item
));

export const removeScheduleItems = (scheduleData, sectionIndex, scheduleIndex) => scheduleData.map((item, i) => (
  i === sectionIndex
    ? { ...item, schedule: item.schedule.filter((_, index) => index !== scheduleIndex) }
    : item
));
