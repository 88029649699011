/* eslint-disable no-console */
import PropTypes from 'prop-types';
import React from 'react';
import Select from 'react-select';
import styled from 'styled-components';

function FreeTextDropdown({ items, onChange, selectedValue, placeholder, setSearchTerm, noMessage }) {
  const messageCheck = noMessage ? null : 'No episode options.';
  const handleInputChange = (inputValue) => {
    setSearchTerm(inputValue);
  };
  return (
    <DropDownWrapper>
      <Select
        value={selectedValue || ''}
        onChange={onChange}
        options={items}
        closeMenuOnSelect
        placeholder={placeholder}
        onInputChange={handleInputChange}
        noOptionsMessage={() => messageCheck}
        openMenuOnClick={false}
      />
    </DropDownWrapper>
  );
}

const DropDownWrapper = styled.div`
  width: 46.5%;
`;

FreeTextDropdown.propTypes = {
  items: PropTypes.arrayOf(PropTypes.object).isRequired,
  onChange: PropTypes.func.isRequired,
  setSearchTerm: PropTypes.func.isRequired,
  selectedValue: PropTypes.string,
  placeholder: PropTypes.string,
  noMessage: PropTypes.bool,
};

FreeTextDropdown.defaultProps = {
  selectedValue: '',
  placeholder: '',
  noMessage: null,
};

export default FreeTextDropdown;
