/* eslint-disable no-console */
import PropTypes from 'prop-types';
import React, { useState, useEffect, useCallback } from 'react';
import HTML5Backend from 'react-dnd-html5-backend';
import { DndProvider } from 'react-dnd';
import SortableCard from '../SortableCard';
import SearchClips from '../SearchClips';
import useDebounce from '../../utilities/helpers/useDebounce';

function Search({ squidexShows, clipsApi, showIsLatestField, showCommentFields, filteredShowsInitial }) {
  const [customField, setCustomField] = useState({});
  const [playlist, setPlaylist] = useState([]);
  const [showSearchTerm, setShowSearchTerm] = useState('');
  const [shows, setShows] = useState([]);

  useEffect(() => {
    // Initializes a custom squidex field and fetches the shows
    const field = new window.SquidexFormField();

    // Loads saved data
    field.onValueChanged((value) => {
      // console.log('value: ', JSON.parse(value));
      if (!value) {
        return;
      }
      setPlaylist(JSON.parse(value));
    });

    setCustomField(field);
  }, []);

  const debouncedSearchTerm = useDebounce(showSearchTerm, 500);

  useEffect(() => {
    if (showSearchTerm) {
      // Initializes a custom squidex field and fetches the shows
      const field = new window.SquidexFormField();

      field.onInit(async (context) => {
        if (context) {
          const podcasts = await squidexShows(context, showSearchTerm);
          const showsDropdown = [];
          podcasts.forEach((podcast) => {
            showsDropdown.push({ text: podcast.name, value: podcast.playlistId, label: podcast.name });
          });

          setShows(showsDropdown);
        }
      });
      setCustomField(field);
    }
  }, [debouncedSearchTerm]);

  const handleOnSelectEpisode = ({
    index,
    playlistId,
    episodeId,
    comment,
    commentator,
    isLatest,
  }) => {
    const newPlaylist = [...playlist];
    newPlaylist[index] = {
      playlistId,
      episodeId,
      commentator: !commentator && comment ? 'Editors Review' : commentator,
      comment,
      isLatest: isLatest || false,
    };

    setPlaylist(newPlaylist);
    // Only keep if playlistId and episodeId are selected
    const validList = newPlaylist.filter(x => x.playlistId && x.episodeId);
    customField.valueChanged(JSON.stringify(validList));
  };

  const handleOnDeleteItem = (index) => {
    const newPlaylist = [...playlist];
    newPlaylist.splice(index, 1);

    setPlaylist(newPlaylist);
    customField.valueChanged(JSON.stringify(newPlaylist));
  };

  const handleNewItemClick = () => {
    const newPlaylist = [...playlist];

    // Don't allow to create empty entries in the playlist
    if (newPlaylist.length === 0 || newPlaylist[newPlaylist.length - 1].episodeId) {
      newPlaylist.push({ playlistId: '', episodeId: '', isLatest: false });

      setPlaylist(newPlaylist);
      customField.valueChanged(JSON.stringify(newPlaylist));
    }
  };

  // React DnD function that sorts the playlist
  const moveCard = useCallback(
    (startIndex, endIndex) => {
      const newPlaylist = [...playlist];

      const dragCard = newPlaylist[startIndex];
      newPlaylist[startIndex] = newPlaylist[endIndex];
      newPlaylist[endIndex] = dragCard;

      setPlaylist(newPlaylist);
      customField.valueChanged(JSON.stringify(newPlaylist));
    },
    [playlist],
  );

  return (
    <>
      <div className="container-fluid playlist-wrapper">
        <DndProvider backend={HTML5Backend}>
          {
            playlist.map((item, i) => (
              <SortableCard
                key={item.episodeId || 0}
                index={i}
                id={item.episodeId || '0'}
                moveCard={moveCard}
              >
                <SearchClips
                  key={item.episodeId}
                  index={i}
                  shows={shows}
                  clipsApi={clipsApi}
                  onSelectEpisode={handleOnSelectEpisode}
                  onDeleteItem={handleOnDeleteItem}
                  selectedItem={item}
                  playlist={playlist}
                  showIsLatestField={showIsLatestField}
                  showCommentFields={showCommentFields}
                  setShowSearchTerm={setShowSearchTerm}
                  showSearchTerm={showSearchTerm}
                  filteredShowsInitial={filteredShowsInitial}
                />
              </SortableCard>
            ))
          }
        </DndProvider>
        <div className="new-item">
          <button type="button" onClick={handleNewItemClick} className="btn btn-success">
            New item
          </button>
        </div>
      </div>
    </>
  );
}

Search.propTypes = {
  squidexShows: PropTypes.func.isRequired,
  clipsApi: PropTypes.func.isRequired,
  filteredShowsInitial: PropTypes.func.isRequired,
  showIsLatestField: PropTypes.bool,
  showCommentFields: PropTypes.bool,
};

Search.defaultProps = {
  showIsLatestField: false,
  showCommentFields: false,
};

export default Search;
