import React from 'react';
import PropTypes from 'prop-types';
import { ACTION_TYPES } from './hooks/schedule/useSchedule';

export default function ScheduleSection({ children, dispatch, sectionIndex }) {
  function handleRemoveScheduleSection() {
    dispatch({ type: ACTION_TYPES.REMOVE_SCHEDULE_SECTION, sectionIndex });
  }

  return (
    <div className="card mb-3 bg-light text-start">
      <div className="card-body">
        <div className="d-flex">
          <div className="text-start mb-3">
            <h4>Select the state(s) which apply *</h4>
            <p className="text-muted"><small>Select state(s) where the schedule is to be broadcast.</small></p>
          </div>
          <i role="button" tabIndex={0} aria-hidden="true" data-testid="delete-schedule-section" className="bi-trash text-danger ms-auto p-2" onClick={handleRemoveScheduleSection} />
        </div>
        {children}
      </div>
    </div>
  );
}

ScheduleSection.propTypes = {
  children: PropTypes.arrayOf(PropTypes.object).isRequired,
  sectionIndex: PropTypes.number.isRequired,
  dispatch: PropTypes.func.isRequired,
};
