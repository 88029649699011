import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Dropdown from '../../Dropdown';

function StationSelectorItem({
  stations,
  index,
  selectedItem,
  onSelectStation,
  onDeleteItem,
}) {
  const [selectedStation, setSelectedStation] = useState(selectedItem.slug);
  const [selectedStationItem, setStationItem] = useState({ index, ...selectedItem });

  useEffect(() => {
    const { slug } = selectedItem;
    if (slug) {
      setSelectedStation(slug);
    }
  }, []);

  const handleOnStationsChange = (e) => {
    const slug = e.target.value;
    const i = e.target.selectedIndex;
    const name = e.target[i].text;
    setSelectedStation(slug);
    // If Station changed, reset station selection to empty
    const stationItem = { ...selectedStationItem };
    stationItem.index = index;
    stationItem.name = name;
    stationItem.slug = slug;
    setStationItem(stationItem);
    onSelectStation(stationItem);
  };

  const handleOnDeleteClick = () => {
    onDeleteItem(index);
  };

  return (
    <div className="station-item">
      <div className="row">
        <Dropdown
          editorId="station-dropdown"
          editorLabel="Station (required)"
          editorCss="textbox"
          items={stations}
          onChange={handleOnStationsChange}
          selectedValue={selectedStation}
        />
        <button type="button" onClick={handleOnDeleteClick} className="btn btn-danger btn-sm delete-btn btn-episode-delete">Delete</button>
      </div>
    </div>
  );
}

StationSelectorItem.propTypes = {
  stations: PropTypes.arrayOf(PropTypes.object).isRequired,
  index: PropTypes.number.isRequired,
  onSelectStation: PropTypes.func.isRequired,
  onDeleteItem: PropTypes.func.isRequired,
  selectedItem: PropTypes.shape({
    name: PropTypes.string,
    slug: PropTypes.string,
  }).isRequired,
};

StationSelectorItem.defaultProps = {
};

export default StationSelectorItem;
