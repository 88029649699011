/* eslint-disable no-console */
import React from 'react';
import SearchShows from '../SearchShows';
import {
  fetchPodcastTrailers,
  fetchPodcastOneShows,
} from '../../utilities/api/pconeApi';
import {
  getTrailerClipsByPlaylistId,
  getFilteredShows,
  getFilteredShowsPlaylistId,
} from '../../utilities/api/pconeXLApi';
import environment from '../../environment';
import { product } from '../../constant';

function CuratedTrailersEditor() {
  const clipsApi = environment === product.podcastone ? fetchPodcastTrailers : getTrailerClipsByPlaylistId;
  const filteredShows = environment.product === product.podcastone ? fetchPodcastOneShows : getFilteredShows;
  const filteredShowsInitial = environment.product === product.podcastone ? fetchPodcastOneShows : getFilteredShowsPlaylistId;
  return (
    <SearchShows squidexShows={filteredShows} clipsApi={clipsApi} filteredShowsInitial={filteredShowsInitial} />
  );
}

export default CuratedTrailersEditor;
