/* eslint-disable no-console */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import FreeTextDropdown from '../FreeTextDropdown';
import useDebounce from '../../utilities/helpers/useDebounce';
import Textbox from '../Textbox';
import Textarea from '../Textarea';
import Checkbox from '../Checkbox';

function EpisodeSelectorItem({
  shows,
  index,
  selectedItem,
  onSelectEpisode,
  onDeleteItem,
  playlist,
  clipsApi,
  showIsLatestField,
  showCommentFields,
  setShowSearchTerm,
  showSearchTerm,
  filteredShowsInitial,
}) {
  const [episodes, setEpisodes] = useState([]);
  const [selectedShow, setSelectedShow] = useState(selectedItem.playlistId);
  const [selectedEpisodeItem, setEpisodeItem] = useState({ index, ...selectedItem });

  const [episodeSearchTerm, setEpisodeSearchTerm] = useState('');

  const selecetedShowObject = selectedShow && shows.find(i => i.value === selectedShow);
  const showsPlaceholder = selecetedShowObject && selecetedShowObject.label;

  const selecetedEpisodeObject = episodes.find(i => i.value === selectedEpisodeItem.episodeId);
  const episodesPlaceholder = selecetedEpisodeObject && selecetedEpisodeObject.label;
  const [savedEpisodesPlaceholder, setSavedEpisodesPlaceholder] = useState();

  const [savedShows, setSavedShows] = useState();
  const savedShowsPlaceholder = savedShows && savedShows[0].name;

  const debouncedSearchTerm = useDebounce(episodeSearchTerm, 500);

  useEffect(() => {
    const { playlistId } = selectedItem;
    // setCustomField(field);
    if (playlistId) {
      setSelectedShow(playlistId);
    }
    const field = new window.SquidexFormField();
    field.onInit(async (context) => {
      if (context && !showSearchTerm && selectedShow) {
        const podcasts = await filteredShowsInitial(context, selectedShow);
        setSavedShows(podcasts);
      }
    });
  }, []);

  useEffect(() => {
    async function fetchEpisodes() {
      if (!selectedItem.episodeId && !episodeSearchTerm) {
        setEpisodes([]);
      } else {
        const clips = !episodeSearchTerm && selectedItem.episodeId ? await clipsApi(selectedShow, selectedItem.episodeId) : await clipsApi(selectedShow, false, episodeSearchTerm);

        setSavedEpisodesPlaceholder(clips[0] && clips[0].Title);
        // Remove episodes that are already in the playlist
        const filteredClips = clips.filter((item) => {
          const found = playlist.findIndex(row => row.episodeId === item.Id);
          if (found > -1 && found !== index) {
            return false;
          }
          return true;
        });

        const clipsDropdown = [];
        filteredClips.forEach((clip) => {
          clipsDropdown.push({ value: clip.Id, text: clip.Title, label: clip.Title });
        });

        setEpisodes(clipsDropdown);
      }
    }

    if (selectedShow) {
      fetchEpisodes();
    }
  }, [selectedShow, debouncedSearchTerm]);

  const handleOnShowsChange = (selected) => {
    const playlistId = selected.value;
    setSelectedShow(playlistId);
    // If Podcast changed, reset episode selection to empty
    const episodeItem = { ...selectedEpisodeItem };
    episodeItem.index = index;
    episodeItem.playlistId = playlistId;
    episodeItem.episodeId = '';
    setEpisodeItem(episodeItem);
  };

  const handleOnEpisodesChange = (selected) => {
    const episodeItem = {
      ...selectedEpisodeItem,
      episodeId: selected.value,
    };
    setEpisodeItem(episodeItem);
    onSelectEpisode(episodeItem);
  };

  const handleOnCheckboxChange = (e) => {
    // select top most from the episode item
    const latestEpisodeId = episodes[1] ? episodes[1].value : '';
    const isLatest = e.target.checked;
    const episodeItem = {
      ...selectedEpisodeItem,
      isLatest,
    };
    // if isLatest is checked, assign the episodeId to latestEpisodeId
    if (isLatest && latestEpisodeId) {
      episodeItem.episodeId = latestEpisodeId;
    }
    setEpisodeItem(episodeItem);
    onSelectEpisode(episodeItem);
  };

  const handleOnCommentatorChange = (e) => {
    const episodeItem = {
      ...selectedEpisodeItem,
      commentator: e.target.value,
    };
    setEpisodeItem(episodeItem);
    onSelectEpisode(episodeItem);
  };

  const handleOnCommentChange = (e) => {
    const episodeItem = {
      ...selectedEpisodeItem,
      comment: e.target.value,
    };
    setEpisodeItem(episodeItem);
    onSelectEpisode(episodeItem);
  };

  const handleOnDeleteClick = () => {
    onDeleteItem(index);
  };

  return (
    <div className="playlist-item">
      <div className="row">
        <FreeTextDropdown
          items={shows}
          onChange={handleOnShowsChange}
          selectedValue={selectedShow}
          placeholder={showsPlaceholder || savedShowsPlaceholder || 'Search Shows...'}
          setSearchTerm={setShowSearchTerm}
          noMessage
        />
        <FreeTextDropdown
          editorLabel="Episode (required)"
          items={episodes}
          onChange={handleOnEpisodesChange}
          selectedValue={selectedEpisodeItem.episodeId}
          placeholder={episodesPlaceholder || savedEpisodesPlaceholder || 'Search Episodes...'}
          setSearchTerm={setEpisodeSearchTerm}
          noMessage
        />
        {showIsLatestField
          ? (
            <Checkbox
              editorId="playlist-episode-islatest"
              editorLabel="Is Latest"
              selectedValue={selectedEpisodeItem ? selectedEpisodeItem.isLatest : false}
              onChange={handleOnCheckboxChange}
            />
          )
          : (
            <button
              type="button"
              onClick={handleOnDeleteClick}
              className="btn btn-danger btn-sm delete-btn btn-episode-delete"
            >
              Delete
            </button>
          )}
      </div>
      {showCommentFields && (
        <div className="row">
          <Textarea
            editorId="playlist-episode-comment"
            editorLabel="Episode Comment"
            selectedValue={selectedEpisodeItem && selectedEpisodeItem.comment}
            onChange={handleOnCommentChange}
          />
          <Textbox
            editorId="playlist-episode-commentator"
            editorLabel="Episode Commentator"
            selectedValue={selectedEpisodeItem && selectedEpisodeItem.commentator}
            onChange={handleOnCommentatorChange}
          />
          <button type="button" onClick={handleOnDeleteClick} className="btn btn-danger btn-sm delete-btn btn-episode-delete">Delete</button>
        </div>
      )}
    </div>
  );
}

EpisodeSelectorItem.propTypes = {
  shows: PropTypes.arrayOf(PropTypes.object).isRequired,
  index: PropTypes.number.isRequired,
  onSelectEpisode: PropTypes.func.isRequired,
  onDeleteItem: PropTypes.func.isRequired,
  showSearchTerm: PropTypes.string.isRequired,
  setShowSearchTerm: PropTypes.func.isRequired,
  playlist: PropTypes.arrayOf(PropTypes.object).isRequired,
  clipsApi: PropTypes.func.isRequired,
  selectedItem: PropTypes.shape({
    playlistId: PropTypes.string,
    episodeId: PropTypes.string,
  }).isRequired,
  showIsLatestField: PropTypes.bool,
  showCommentFields: PropTypes.bool,
  filteredShowsInitial: PropTypes.func.isRequired,
};

EpisodeSelectorItem.defaultProps = {
  showIsLatestField: false,
  showCommentFields: false,
};

export default EpisodeSelectorItem;
