/* eslint-disable no-console */
const handleStateSelection = (sectionIndex, scheduleData, value, checked) => {
  if (checked) {
    return scheduleData.map((item, _sectionIndex) => {
      if (_sectionIndex === sectionIndex) {
        return { ...item, timezones: [...item.timezones, value] };
      }
      return item;
    });
  }

  return scheduleData.map((item, itemIndex) => {
    if (itemIndex === sectionIndex) {
      return { ...item, timezones: item.timezones.filter(timezoneItem => timezoneItem.state !== value.state) };
    }
    return item;
  });
};

export default handleStateSelection;
