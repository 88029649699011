/* eslint-disable no-console */
/* eslint-disable no-underscore-dangle */
import axios from 'axios';
import { get, isEmpty } from 'lodash';
import environment from '../../environment';

const qryShowsWithPlaylistId = `query {
  queryShowContents(orderby:"data/name/iv", top:500) {
    flatData {
      name
      playlistId
    }
  }
}`;

const qryStations = `query {
  queryStationContents(orderby:"data/name/iv", top:500) {
    flatData {
      name slug latitude longitude
      network {
        flatData {
          slug
        }
      }
    }
  }
}`;

const filterShows = searchTerm => `query {
  queryShowContents(filter:"contains(data/name/iv, '${searchTerm}')") {
    flatData {
      name
      playlistId
    }
  }
}`;

const filterShowsPlaylistId = playlistId => `query {
  queryShowContents(filter:"contains(data/playlistId/iv, '${playlistId}')") {
    flatData {
      name
      playlistId
    }
  }
}`;

export async function getSquidexShows(context) {
  try {
    const baseUrl = `${context.apiUrl}/content/${environment.squidex.projectId}/graphql`;
    const queryData = JSON.stringify({ query: qryShowsWithPlaylistId });
    const squidexShowData = await axios.post(baseUrl, queryData, {
      responseType: 'json',
      headers: {
        Authorization: `Bearer ${context.user.user.access_token}`,
        'Content-Type': 'application/json',
      },
    });

    const squidexShows = get(squidexShowData, 'data.data.queryShowContents', []);
    if (isEmpty(squidexShows)) return [];
    return squidexShows.map(x => ({
      name: x.flatData.name,
      playlistId: x.flatData.playlistId,
    }));
  } catch (ex) {
    console.log(`ERROR in ${environment.product} getSquidexShows`, ex);
    return [];
  }
}

export async function getSquidexStations(context) {
  try {
    const baseUrl = `${context.apiUrl}/content/${environment.squidex.projectId}/graphql`;
    const queryData = JSON.stringify({ query: qryStations });
    const squidexStationData = await axios.post(baseUrl, queryData, {
      responseType: 'json',
      headers: {
        Authorization: `Bearer ${context.user.user.access_token}`,
        'Content-Type': 'application/json',
      },
    });
    const squidexStations = get(squidexStationData, 'data.data.queryStationContents', []);
    if (isEmpty(squidexStations)) return [];
    return squidexStations.filter(s => s.flatData.network[0]
      && s.flatData.network[0].flatData.slug === environment.squidex.editorNetwork
      && !isEmpty(s.flatData.latitude)
      && !isEmpty(s.flatData.longitude))
      .map(x => ({
        name: x.flatData.name,
        slug: x.flatData.slug,
      }));
  } catch (ex) {
    console.log(`ERROR in ${environment.product} getSquidexStations`, ex);
    return [];
  }
}

export async function getClipsByPlaylistId(playlistId, episodeId, searchTerm = '', isTrailers = false, size = 1000) {
  const searchString = episodeId ? ` Id:"${episodeId}"` : ` Title:"*"${searchTerm}"*"`;
  try {
    if (!playlistId) return [];

    const { authStr, host, indices } = environment.elastic;
    const baseUrl = `https://${host}/${indices.omnyClips}/_search`;

    const qryTrailers = isTrailers ? ' AND EpisodeType:"Trailer"' : '';
    const q = `PlaylistIds:"${playlistId}" AND Visibility:"Public"${qryTrailers} AND ${searchString}`;

    const rawData = await axios.get(baseUrl, {
      responseType: 'json',
      headers: {
        'Content-Type': 'application/json',
        'X-Requested-With': 'XMLHttpRequest',
        authorization: `Basic ${authStr}`,
      },
      params: {
        q,
        _source: 'Id,Title',
        sort: 'PublishedUtc:desc',
        size,
      },
    });
    const resultData = get(rawData, 'data.hits.hits', []);
    return isEmpty(resultData) ? [] : resultData.map(item => item._source);
  } catch (ex) {
    console.log(
      `ERROR in ${environment.product} getClipsByPlaylistId ${playlistId} 
      | isTrailers ${isTrailers}`, ex
    );
    return [];
  }
}

export async function getTrailerClipsByPlaylistId(playlistId, episodeId) {
  return getClipsByPlaylistId(playlistId, episodeId, '', true);
}

export async function getSquidexShowsByCategorySlug(slug) {
  try {
    if (!slug) { return []; }
    const { authStr, host, indices } = environment.elastic;
    const baseUrl = `https://${host}/${indices.squidexShows}/_search`;
    const body = JSON.stringify({
      query: {
        nested: {
          path: 'categories',
          query: {
            term: {
              'categories.slug': slug,
            },
          },
        },
      },
      _source: ['name', 'slug', 'playlistId'],
      size: 500,
      sort: 'slug',
    });
    const squidexShowsES = await axios.post(baseUrl, body, {
      responseType: 'json',
      headers: {
        'Content-Type': 'application/json',
        'X-Requested-With': 'XMLHttpRequest',
        authorization: `Basic ${authStr}`,
      },
    });
    const squidexShows = get(squidexShowsES, 'data.hits.hits', []);
    return isEmpty(squidexShows) ? [] : squidexShows.map(item => item._source);
  } catch (ex) {
    console.log(`ERROR in ${environment.product} getSquidexShowsByCategorySlug`, ex);
    return [];
  }
}

export async function getFilteredShows(context, searchTerm) {
  try {
    const baseUrl = `${context.apiUrl}/content/${environment.squidex.projectId}/graphql`;
    const rawData = filterShows(searchTerm);
    const queryData = JSON.stringify({ query: rawData });
    const squidexShowData = await axios.post(baseUrl, queryData, {
      responseType: 'json',
      headers: {
        Authorization: `Bearer ${context.user.user.access_token}`,
        'Content-Type': 'application/json',
      },
    });

    const squidexShows = get(squidexShowData, 'data.data.queryShowContents', []);
    if (isEmpty(squidexShows)) return [];
    return squidexShows.map(x => ({
      name: x.flatData.name,
      playlistId: x.flatData.playlistId,
    }));
  } catch (ex) {
    console.log(`ERROR in ${environment.product} getFilteredShows`, ex);
    return [];
  }
}

export async function getFilteredShowsPlaylistId(context, playlistId) {
  try {
    const baseUrl = `${context.apiUrl}/content/${environment.squidex.projectId}/graphql`;
    const rawData = filterShowsPlaylistId(playlistId);
    const queryData = JSON.stringify({ query: rawData });
    const squidexShowData = await axios.post(baseUrl, queryData, {
      responseType: 'json',
      headers: {
        Authorization: `Bearer ${context.user.user.access_token}`,
        'Content-Type': 'application/json',
      },
    });

    const squidexShows = get(squidexShowData, 'data.data.queryShowContents', []);
    if (isEmpty(squidexShows)) return [];
    return squidexShows.map(x => ({
      name: x.flatData.name,
      playlistId: x.flatData.playlistId,
    }));
  } catch (ex) {
    console.log(`ERROR in ${environment.product} getFilteredShowsPlaylistId`, ex);
    return [];
  }
}
