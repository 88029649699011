import React from 'react';

import Scheduling from './Scheduling';

function SchedulingContainer() {
  const squidexCustomField = new window.SquidexFormField();
  return (
    <div className="container-fluid">
      <div className="card card-body text-start">
        <Scheduling customField={squidexCustomField} />
      </div>
    </div>
  );
}

export default SchedulingContainer;
