/* eslint no-console: 0 */
import React from 'react';
import PropTypes from 'prop-types';

import { ACTION_TYPES } from './hooks/schedule/useSchedule';
import { SortableContainer, SortableScheduleItem } from './SortableElements';
// import generateKey from './utils/generateKey';

export default function ScheduleItems({ dispatch, sectionIndex, scheduleData }) {
  function handleAddScheduleItem() {
    dispatch({
      type: ACTION_TYPES.ADD_SCHEDULE_ITEM,
      payload: {
        sectionIndex,
      },
    });
  }

  function handleSortSchedules({ oldIndex, newIndex }) {
    dispatch({
      type: ACTION_TYPES.SORT_SCHEDULES,
      payload: { sectionIndex, oldIndex, newIndex },
    });
  }

  return (
    <div className="mt-5">

      {scheduleData.schedule.length > 0 ? (
        <div className="text-start mb-3">
          <h4>Schedule days and times.</h4>
          <p className="text-muted mb-2"><small>Both start and end times are to be 24hr time.</small></p>
        </div>
      ) : ''}

      <SortableContainer
        onSortEnd={handleSortSchedules}
        useDragHandle
        lockAxis="y"
      >
        <ul className="ps-1" data-testid="schedule-items">
          {scheduleData.schedule.map((data, scheduleIndex) => (
            <SortableScheduleItem
              key={`sortableScheduleItem-${data.id}`}
              dispatch={dispatch}
              sectionIndex={sectionIndex}
              scheduleIndex={scheduleIndex}
              scheduleData={data}
              index={scheduleIndex}
            />

          ))}
        </ul>
      </SortableContainer>

      <div>
        <button type="button" className="btn btn-outline-success mt-3" onClick={handleAddScheduleItem}>Add item</button>
      </div>
    </div>
  );
}

ScheduleItems.defaultProps = {
  scheduleData: {},
};

ScheduleItems.propTypes = {
  sectionIndex: PropTypes.number.isRequired,
  dispatch: PropTypes.func.isRequired,
  scheduleData: PropTypes.shape({
    schedule: PropTypes.arrayOf(
      PropTypes.shape({
        isScheduled: PropTypes.bool,
        day: PropTypes.string,
        start: PropTypes.string,
        end: PropTypes.string,
      }),
    ),
    timezones: PropTypes.arrayOf(PropTypes.shape({
      name: PropTypes.string,
      value: PropTypes.arrayOf(
        PropTypes.shape({
          state: PropTypes.string,
          timezone: PropTypes.string,
        })
      ),
    })),
  }),
};
