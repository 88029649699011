import PropTypes from 'prop-types';
import React from 'react';

function Dropdown({ editorId, editorLabel, editorCss, items, onChange, selectedValue, isRequired }) {
  editorCss = editorCss ? `form-control ${editorCss}` : 'form-control';
  return (
    <div className="dropdown">
      <label htmlFor={editorId}>
        {`${editorLabel}: ${isRequired ? '*' : ''}`}
        <select id={editorId} name={editorId} onChange={onChange} value={selectedValue || ''} className={editorCss}>
          {
            items.map(item => <option value={item.value} key={item.value}>{item.text}</option>)
          }
        </select>
      </label>
    </div>
  );
}

Dropdown.propTypes = {
  editorId: PropTypes.string.isRequired,
  editorLabel: PropTypes.string.isRequired,
  editorCss: PropTypes.string,
  items: PropTypes.arrayOf(PropTypes.object).isRequired,
  onChange: PropTypes.func.isRequired,
  selectedValue: PropTypes.string,
  isRequired: PropTypes.string,
};

Dropdown.defaultProps = {
  editorCss: '',
  selectedValue: '',
  isRequired: false,
};

export default Dropdown;
