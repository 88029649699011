/* eslint-disable no-underscore-dangle */
/* eslint-disable no-console */
import axios from 'axios';
import { get, isEmpty } from 'lodash';
import environment from '../../environment';

const qryPodcastsWithPlaylistId = `query {
  queryPodcastContents(orderby:"data/name/iv", top:500) {
    flatData {
      name
      playlistId
    }
  }
}`;

export async function fetchPodcastOneShows(context) {
  try {
    const baseUrl = `${context.apiUrl}/content/${environment.squidex.projectId}/graphql`;
    const queryData = JSON.stringify({ query: qryPodcastsWithPlaylistId });

    const squidexShowData = await axios.post(baseUrl, queryData, {
      responseType: 'json',
      headers: {
        Authorization: `Bearer ${context.user.user.access_token}`,
        'Content-Type': 'application/json',
      },
    });

    const squidexShows = get(squidexShowData, 'data.data.queryPodcastContents', []);
    if (isEmpty(squidexShows)) {
      return [];
    }

    return squidexShows.map(x => ({
      name: x.flatData.name,
      playlistId: x.flatData.playlistId,
    }));
  } catch (ex) {
    console.log('ERROR in pcone fetchPodcastOneShows', ex);
    return [];
  }
}

export async function fetchPodcastOneEpisodes(playlistId, isTrailers = false, size = 100) {
  try {
    if (!playlistId) return [];
    const { authStr, host, indices } = environment.elastic;
    const baseUrl = `https://${host}/${indices.omnyClips}/_search`;

    const qryTrailers = isTrailers ? ' AND EpisodeType:"Trailer"' : '';
    const q = `PlaylistIds:"${playlistId}" AND Visibility:"Public"${qryTrailers}`;

    const rawData = await axios.get(baseUrl, {
      responseType: 'json',
      headers: {
        'Content-Type': 'application/json',
        'X-Requested-With': 'XMLHttpRequest',
        authorization: `Basic ${authStr}`,
      },
      params: {
        q,
        _source: 'Id,Title',
        sort: 'PublishedUtc:desc',
        size,
      },
    });
    const resultData = get(rawData, 'data.hits.hits', []);
    return isEmpty(resultData) ? [] : resultData.map(item => item._source);
  } catch (ex) {
    console.log(
      `ERROR in pcone fetchPodcastOneEpisodes ${playlistId} 
      | isTrailers ${isTrailers}`, ex
    );
    return [];
  }
}

export async function fetchPodcastTrailers(playlistId) {
  return fetchPodcastOneEpisodes(playlistId, true);
}
